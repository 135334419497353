import React, { Component } from "react";

class Intro extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);

    document.addEventListener("loadDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  introAnim() {
    setTimeout(() => {
      this.introImg.classList.remove("intro-hero-img-init");
      setTimeout(() => {
        const fadeItems = [
          ...this.slideDiv.querySelectorAll(".intro-slide-item"),
        ];
        fadeItems.map((f) => f.classList.remove("fade"));
      }, 600);
    }, 3000);
  }

  outroAnim() {
    this.slideDiv.classList.add("start-outro");
    this.introImg.classList.add("intro-hero-img-outro");
  }

  componentWillUnmount() {
    document.removeEventListener("loadDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
  }

  render() {
    return (
      <div
        ref={(ref) => (this.slideDiv = ref)}
        className="slide clickable start-init"
      >
        <div className="intro-hero-container">
          <div
            ref={(ref) => (this.introImg = ref)}
            className="intro-hero-img intro-hero-img-init"
          ></div>
          <div className="intro-fold-logo intro-slide-item fade"></div>
          <div
            className="intro-start-button intro-slide-item fade"
            onClick={() => this.props.GoToState("OVERVIEW", 1000)}
          >
            START
          </div>
          <div className="intro-sub-container intro-slide-item fade">
            <div className="intro-sub-content">
              87,174 sq ft brand new workspace <br />
              in Kensington Village
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
